.connectivity-issue {

    h2 {
        margin-bottom: box-model(s);
    }

    .modal-body {
        padding-right: box-model(xxl);
        padding-bottom: 0;
    }

    .alert {
        @include font-weight(semibold);
        margin-bottom: box-model(l);
    }

    .subtitle {
        margin-bottom: box-model(l);
    }

    .section {
        border: 1px solid color(base-300);
        border-radius: $large-border-radius;
        padding: box-model(m);
        margin-bottom: box-model(l);

        ul {
            margin-bottom: 0;
        }
        li {
            padding-bottom: box-model(s);

            &:last-of-type {
                padding-bottom: 0;
            }
        }
        
    }
}
