// Colors
// ------
@import "./global-colors";

:root {
    @each $name in map-keys($global-light-colors) {
        --#{$name}: #{light-color($name)};
        --#{$name}-rgb: #{light-color-rgb($name)};
    }
}

@mixin dark-theme {
    @each $name in map-keys($global-dark-colors) {
        --#{$name}: #{dark-color($name)};
        --#{$name}-rgb: #{dark-color-rgb($name)};
    }
}

// Cycling-repeated colors
// NOTE: order matters
$alternated-colors:
    light-color(alt-1-300),
    light-color(warning-300),
    light-color(success-300),
    light-color(alert-300),
    light-color(primary-300),
    light-color(error-300);
