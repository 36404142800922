$details-description-width: 60%;

.modal-showcase {
    padding-bottom: box-model(xxxl);

    .modal-header > header {
        position: relative;
        margin-top: box-model(xl);

        .subtitle {
            margin-bottom: 0;
        }
    }

    h4 {
        border-bottom: 1px solid color(base-500);
        padding-bottom: box-model(xs);
        margin-bottom: box-model(m);
    }

    .showcase {
        margin-left: - box-model(xxl);
        margin-right: - box-model(xxl);
        img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: $base-border-radius;
            max-height: box-model(xs) * 46;
        }
    }

    .details {
        display: grid;
        grid-gap: $base-gutter;
        grid-template-columns: $details-description-width 1fr;

        p {
            margin-bottom: box-model(xl);
        }

        .module {
            margin-bottom: box-model(s);
        }
    }

    .VueCarousel-slide {
        position: relative;

        &::before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            padding-bottom: $ratio-169;
        }
    }
}

// Additional styles for `no-grid` browsers
.no-grid-supported {
    .modal-showcase .details {
        > .description {
            display: inline-block;
            vertical-align: top;
            width: $details-description-width;
            margin-right: box-model(m);
        }
    }
}
