%tooltip {
    @include font-weight(normal);
    background: color(base-700);
    border-radius: $base-border-radius;
    color: color(base-000);
    max-width: $tooltips-max-width;
    padding: box-model(xxs, xs);
    box-sizing: border-box;
    overflow-wrap: break-word;
}

.tooltip {
    @include font-size(s);
    @include line-height(document);

    // This is to prevent flickering.
    // It will eventually be replaced by inline styles from the plugin.
    position: fixed;
    top: 0;
    left: 0;

    display: block;
    z-index: z-index(tooltips);

    &[x-placement^='top'], &.top {
        margin-bottom: box-model(m);

        .tooltip-arrow {
            border-width: box-model(xs, xs, 0, xs);
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -box-model(xs);
            left: calc(50% - #{box-model(xs)});
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='bottom'], &.bottom {
        margin-top: box-model(m);

        .tooltip-arrow {
            border-width: box-model(0, xs, xs, xs);
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -box-model(xs);
            left: calc(50% - #{box-model(xs)});
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='right'], &.right {
        margin-left: box-model(m);

        .tooltip-arrow {
            border-width: box-model(xs, xs, xs, 0);
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -box-model(xs);
            top: calc(50% - #{box-model(xs)});
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^='left'], &.left {
        margin-right: box-model(m);

        .tooltip-arrow {
            border-width: box-model(xs, 0, xs, xs);
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -box-model(xs);
            top: calc(50% - #{box-model(xs)});
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover:not(.tooltip-comp) {
        @include font-size(base);

        .tooltip-arrow {
            display: unset;
            border-color: color(base-000);
        }

        .popover-inner {
            background-color: color(base-000);
            color: color(base-700);
            padding: box-model(m);
            border-radius: $base-border-radius;
            box-shadow: $base-box-shadow;
        }

        .actions {
            margin-top: box-model(l);
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }

    .tooltip-inner {
        text-align: left;

        @extend %tooltip;
    }

    .tooltip-arrow {
        display: none;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: box-model(xs);
        border-color: black;
    }
}



.v-popover {
    position: absolute;
}

*[delayed-tooltip] {
    position: relative;

    &::after {
        @extend %tooltip;

        opacity: 0;
        content: attr(delayed-tooltip);
        position: absolute;
        left: 100%;
        top: 50%;
        white-space: nowrap;
        transform: translateY(-50%);
        margin-left: box-model(xs);
        z-index: -1;
    }

    &:hover::after {
        transition: $base-z-index-transition, $base-opacity-transition $tooltips-delay-time;
        opacity: 1;
        z-index: z-index(tooltips);
    }
}

.menu-tooltip {
    margin-left: $main-menu-minimized-width !important;
    margin-top: font-size-original(xs);
    top: -100vh;
}

.menu-logo-tooltip {
    top: -100vh;
}
