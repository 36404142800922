// Grids
// -----

// Grid breakpoints
// -
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// Media queries ranges
// -
// Define a set of max and min ranges for each breakpoint.
$screen-xs-min: breakpoint(xs);
$screen-xs-max: breakpoint(sm);
$screen-sm-min: breakpoint(sm) + 1;
$screen-sm-max: breakpoint(md);
$screen-md-min: breakpoint(md) + 1;
$screen-md-max: breakpoint(lg);
$screen-lg-min: breakpoint(lg) + 1;
$screen-lg-max: breakpoint(xl);
$screen-xl-min: breakpoint(xl) + 1;

$base-gutter: box-model(xl);
$grid-columns: 12;
$fluid-grid-gutter-width: 3.247%;
$fluid-grid-column-width: math.div(100, $grid-columns) - $fluid-grid-gutter-width + math.div($fluid-grid-gutter-width, $grid-columns);

// Layout
// ------

$main-container-min-width: 1024px;
$main-container-max-width: 1200px;
$list-tile-width-dashboard: 232px;
$list-tile-width: 214px;
