// Inline and block code styles
code,
pre {
    @include font-size(s);
    @include secondary-font();
    padding: box-model(m);
    border-radius: $base-border-radius;
}

// Inline code
code {
    padding: box-model(xxs);
    color: color(error-500);
    background-color: color(base-100);
    border: 1px solid color(base-300);
}

// Blocks of code
pre {
    @include line-height(xxs);
    display: block;
    padding: box-model(m);
    margin: 0 0 box-model(m);
    background-color: color(base-100);
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
    white-space: pre;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;

    // Account for some code outputs that place code tags in pre tags
    code {
        padding: 0;
        color: inherit;
        background-color: transparent;
        border: 0;
    }
}
