.user-forms {
    padding-left: $submenu-width;
    margin-bottom: box-model(l);

    .resend-code {
        display: block;
        margin-bottom: box-model(l);
    }
}

.user-token {
    .token-sid {
        margin: box-model(m, auto);
        text-align: center;

        textarea {
            cursor: pointer;
        }
    }
}

.user-tokens {
    .logo {
        max-width: 80px;
        padding-top: box-model(xxs);
        padding-bottom: box-model(xxs);
        text-align: center;

        img {
            max-height: 32px;
            vertical-align: middle;
        }
    }
}


.user-tokens table td {
    vertical-align: middle;
}


.user-management .user-details,
.user-management .delete-user {
    margin-top: box-model(m);
}

