.report-export-modal, .fp-type-export-modal {

    &:not(.polling) {
        .modal-body {
            padding-bottom: 0;
        }
    }

    .modal-body {
        .ic-info-fill {
            margin-left: box-model(xs);
        }
    }

    .fields-list {
        &.full {
            .options-list {
                max-height: none;
            }

            .full-list-toggler {
                i {
                    transform: rotate(180deg);
                }
            }
        }

        > .form-group {
            margin-bottom: box-model(m);
        }

        .options-list {
            overflow: hidden;
            max-height: box-model(xs) * 8;
        }
    }

    .full-list-toggler {
        display: block;
        text-align: center;
        padding: box-model(xs);
        border-top: 1px solid color(base-100);

        i {
            @include icon-size(xs);
            vertical-align: middle;
        }
    }

    .download-link-wrapper {
        text-align: center;
        margin-bottom: box-model(xl);
    }
}
