// Paths
// -----

$images-path: '../img';
$fonts-path: '../fonts';

// Base stuff
// ----------

$base-unit:   8px;
$half-unit:   math.div($base-unit, 2);

// Ratios
// ------

$ratio-169: math.div(9, 16) * 100%;
$ratio-43: math.div(3, 4) * 100%;

// Sizes
// -----

$global-sizes: (
    xxxs: math.div($half-unit, 2), //  2px
    xxs:  $half-unit,              //  4px
    xs:   $base-unit,              //  8px
    s:    $base-unit + $half-unit, // 12px
    m:    $base-unit * 2,          // 16px
    l:    $base-unit * 3,          // 24px
    xl:   $base-unit * 4,          // 32px
    xxl:  $base-unit * 5,          // 40px
    xxxl: $base-unit * 6,          // 48px
    h:    $base-unit * 8,          // 64px
);

$separator-gutter: 20px;
$scrollbars-width: 24px; // This one must be a constant

// Icons sizes
// -----------

$icons-size: (
    xxs: $base-unit * 2, // 16px
    xs:  $base-unit * 3, // 24px
    s:   $base-unit * 4, // 32px
    m:   $base-unit * 5, // 40px
    l:   $base-unit * 6, // 48px
    xl:  $base-unit * 7, // 56px
    xxl: $base-unit * 8, // 64px
);

$icon-side-margin: 10px;
$icon-font-size-ratio: 75; // It means that the font size it will be 75% of the icon size.

// Forms
// -----

$input-height: $base-unit * 4;
$search-input-min-width: 232px;
$form-check-input-gutter: box-model(m);
$form-check-input-margin-y: 3px;

// Border radiuses
// ---------------

$small-border-radius: math.div($half-unit, 2);
$base-border-radius: $half-unit;
$large-border-radius: $base-unit;
$extra-large-border-radius: box-model(m);

// Miscellaneous
// -------------

$base-spinner-size: icon-size(xxl);
$base-box-shadow: 0 $half-unit $base-unit 0 rgba(0, 0, 0, 0.1);

// Transitions
// -----------

$base-shadow-transition: box-shadow .1s ease-in-out;
$base-transform-transition: transform .1s ease-in-out;
$base-text-transition: color .1s linear;
$base-background-color-transition: background-color .1s linear;
$base-border-transition: border-color .1s linear;
$base-opacity-transition: opacity .1s ease-in-out;
$base-z-index-transition: z-index .1s ease-in-out;
$base-width-transition: width .1s ease-in-out;

// Z-indexes
// ---------

$z-indexes: (
    tooltips: 1090,
    fullscreen-controls: 1080,
    checklist: 1060,
    modal: 1050,
    modal-backdrop: 1040,
    dropdowns: 90,
    notifications: 50,
    menu: 10,
    common-toolbar: 9,
    loader: 1
);

@import "variables/colors";
@import "variables/components";
@import "variables/grid";
@import "variables/typography";

// Custom props
// ------------

:root {
    --container-max-columns: #{$grid-columns};
    --grid-vertical-gap: #{box-model(l)};
}
