ul {
    margin: 0 0 box-model(m);
    padding: 0 0 0 box-model(m);

    &:only-child {
        margin-bottom: 0;
    }
}

dl {
    margin: box-model(0, 0, m);
    clear: both;
}

dt {
    @include font-size(s);
    @include font-weight(bold);

    float: left;
    margin-right: box-model(m);
    text-align: right;
    width: 50%;
}

dd {
    @include clearfix;
}

dl.small {
    dt {
        width: 20%;
    }
}

dl.extra-small {
    dt {
        text-align: left;
        width: box-model(m) * 5;
    }
}

li.separator {
    border-top: 1px solid color(base-500);
    margin-top: $separator-gutter;
    padding-bottom: $separator-gutter - 1;
}

.listing-grid {
    display: grid;
    grid-gap: $base-gutter;
    grid-template-columns: repeat(auto-fill, minmax($list-tile-width, 1fr));
    justify-content: space-evenly;

    .list-tile {
        display: inline-block;

        .badge {
            margin-right: 0;
        }

        &.hovering-badge {
            .package-button {
                opacity: 0;
                transition: $base-opacity-transition;
            }

            &:hover .package-button {
                opacity: 1;
            }
        }
    }
}

// Additional styles for `no-grid` browsers
.no-grid-supported .listing-grid {
    margin-top: - box-model(m);
    margin-left: - box-model(m);
    margin-right: - box-model(m);

    .card, .list-tile, .image {
        width: $list-tile-width;
        margin: box-model(m);
        display: inline-block;
    }
}

.packages-changes {
    header {
        padding-top: box-model(m);
        padding-bottom: box-model(m);

        p {
            margin: box-model(m) 150px 0 0;
        }
    }

    .list-item {
        padding-left: box-model(m);
        padding-right: box-model(m);

        &:nth-child(odd) {
            background-color: color(base-000);
        }

        &:nth-child(even) {
            background-color: color(base-100);
        }

        &.highlight, &.highlight:nth-child(odd), &.highlight:nth-child(even) {
            background-color: color(warning-100);
            border: 1px solid color(warning-300);
        }
    }

    .changelog .log {
        border: 0;
        padding-top: 0;
    }
}

.templates-list {
    .workspace-card {
        .content-text {
            min-height: box-model(xs) * 14;
        }

        &.hovering {
            .details {
                opacity: 0;
                transition: $base-opacity-transition;
            }
            &:hover .details {
                opacity: 1;
            }
        }

        .details {
            display: none;
        }
    }
}

.objects-list {
    border-radius: $base-border-radius;
    border: 1px solid color(base-300);

    .object {
        padding: box-model(s);

        &:nth-child(even) {
            background-color: color(base-050);
        }

        &:nth-child(odd) {
            background-color: color(base-000);
        }

        &:first-child {
            border-top-left-radius: $base-border-radius;
            border-top-right-radius: $base-border-radius;
        }

        &:last-child {
            border-bottom-left-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;
        }
    }
}

.action-buttons {
    margin-left: - box-model(xxs);
}

ul.call-to-actions {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.action-control-group {
    vertical-align: top;
}

.emailing-list {

    .contextual-info {
        @include font-size(s);
        @include line-height(xxm);
        clear: both;
        max-height: 0;
        transition: $cta-contextual-info-transition;
        overflow: hidden;

        &.in {
            max-height: box-model(xl);
        }

        .message {
            display: inline-block;
        }

        .message + .message {
            &:before {
                content: '|';
                display: inline-block;
                margin: 0 box-model(xs);
            }
        }
    }
}

.users-list {
    .search-bar {
        float: right;
    }

    table {
        td {
            &.full_name, &.company {
                width: 30%;
            }
        }
    }
}

.table-wrapper {
    .preview {
        width: box-model(xs) * 14;
        text-align: center;
    }

    .image-preview {
        line-height: 1;

        picture {
            display: inline-block;
            line-height: 1;
            font-size: 0;
            border-radius: $small-border-radius;
            overflow: hidden;
        }

        img {
            height: box-model(xxl);
        }
    }

    .tag-cell {
        a span {
            padding: box-model(xxs, s);
            border-radius: box-model(xl);
        }
    }
}

.live-session-links {
    display: inline-block;

    + .btn {
        margin-left: box-model(xs);
    }

    h5 {
        padding: box-model(xs, m);
        margin: 0;
    }

    ul + h5 {
        border-top: color(base-300) 1px solid;
    }

    ul:has(+ h5) {
        margin-bottom: box-model(xs);
    }

    li button {
        padding: box-model(xxs, m);
    }

    i {
        margin-right: box-model(xs);
    }
}
