.expandable {
    &.open {
        header .ctx .toggler i {
            transform: rotate(180deg);
        }

        > section {
            max-height: box-model(xs) * 50;
        }

        &.unlimited-height {
            > section {
                max-height: none;
                overflow: visible;
            }
        }

        &:not(.slim) {
            > section {
                padding-top: box-model(l);
            }
        }

        &.fieldset {
            > section {
                padding-top: box-model(s);
            }
        }
    }

    &:not(.open) > section {
        overflow: hidden;
        max-height: 0;
    }

    &.animated {
        transition: border-color .2s linear;

        header .ctx .toggler i {
            transition: transform .2s linear, color .2s linear;
        }

        > section {
            opacity: 0;
            transition:
                opacity .25s ease-in-out,
                padding .25s ease-in-out,
                max-height .25s ease-in-out;
        }

        &.open {
            > section {
                opacity: 1;
            }
        }
    }

    + .expandable {
        margin-top: box-model(l);
    }

    header {
        display: flex;
        gap: box-model(xs);
        position: relative;

        &.has-action {
            cursor: pointer;
        }

        > * {
            flex: 1 1 auto;
        }

        .ctx {
            flex-grow: 0;
            order: 1;
            display: flex;
            gap: box-model(xxs);

            .toggler {
                cursor: pointer;
                max-height: icon-size(s);
                min-width: box-model(l);
                text-align: center;

                i {
                    @include font-size(document);
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        i.ic-chevron-down,
        i.ic-chevron-up {
            @include font-size(xm);
            color: color(primary-300);
        }
    }
}

.meta-form {
    .expandable {
        border: 1px solid color(base-300);
        border-radius: $large-border-radius;
        margin-bottom: box-model(l);

        &:only-child {
            margin-bottom: 0;
        }

        &.open {
            > section {
                padding: box-model(0, m, m);
            }
        }

        h5 {
            padding: 0;
        }

        > header {
            padding: box-model(s, m);
        }
    }
}
