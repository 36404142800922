.loader {
    $spinner-size: $base-spinner-size;
    $border-thickness: $spinner-size * math.div(25, 100);
    $inner-circle-size: $spinner-size - $border-thickness;

    min-height: $spinner-size + box-model(l);

    &.in-page {
        position: relative;
        min-height: 0;

        > .loading {
            left: 50%;
        }
    }

    &.static {
        position: static;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading {
            display: inline-block;
            position: static;

            .spinner {
                margin: 0;
            }
        }
    }

    &.inline {
        display: inline-block;
        margin: box-model(0, 0, -xxs, xs);
        vertical-align: middle;
    }

    &.medium {
        $spinner-size-medium: math.div($spinner-size, 2);

        .spinner {
            height: $spinner-size-medium;
            margin-left: math.div($spinner-size-medium, -2);
            margin-top: math.div($spinner-size-medium, -2);
            width: $spinner-size-medium;
        }
    }

    &.small {
        $spinner-size-small:math.div( $spinner-size, 4);

        .spinner {
            height: $spinner-size-small;
            margin-left: math.div($spinner-size-small, -2);
            margin-top: math.div($spinner-size-small, -2);
            width: $spinner-size-small;
        }
    }

    > .loading,
    > .loaded {
        transition: $base-opacity-transition, $base-z-index-transition;
        opacity: 0;

        &.in {
            opacity: 1;
        }
    }

    > .loading {
        left: calc(50% + #{math.div($main-menu-width, 2)});
        position: absolute;
        text-align: center;
        top: 50%;
        z-index: -1;

        &.in {
            z-index: z-index(loader);
        }

        > .caption {
            @include font-weight(normal);
            @include font-size(document);
            @include line-height(base);

            margin-top: box-model(xs);
            transform: translateX(-50%);
        }
    }

    // .spinner { /** Note this is defined on the critical path file */ }
}

.v--modal-box .loader {
    .loading {
        left: 50%;
    }
}
