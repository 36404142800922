.alert {
    @include line-height(base);
    background-color: color(base-300);
    border-radius: $base-border-radius;
    border: 1px solid color(base-700);
    border-left-width: $alert-thick-border-size;
    margin-bottom: box-model(xl);
    padding: box-model(s, m);

    &:only-child {
        margin-bottom: 0;
    }

    &.warning, &.alert-warning {
        background-color: color(warning-100);
        border-color: color(warning-300);
    }
    &.error, &.alert-error {
        background-color: color(error-100);
        border-color: color(error-300);
    }
    &.success, &.alert-success {
        background-color: color(success-100);
        border-color: color(success-300);
    }
    &.info, &.alert-info {
        background-color: color(primary-100);
        border-color: color(primary-300);
    }

    .close {
        margin-right: - box-model(xs);
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.alert-notice {
    @extend .alert-info;
}

.alert-danger {
    @extend .alert-error;
}

.initial-failure {
    width: $notification-width;
    margin: $notification-offset-y auto;
}

body.maximized {
    .entitlement-over-usage-notice {
        display: none;
    }
}
