main {
    max-width: none;
}

.container {
    margin: 0 auto;
    max-width: $main-container-max-width;
    padding-left: box-model(m);
    padding-right: box-model(m);
}

.api-documentation {
    @import 'highlight.js/styles/agate';

    padding-bottom: box-model(xl);

    .actions:not(td) {
        float: right;
        margin: 0;
    }

    .security {
        max-width: $screen-sm-max;
        margin: 0 auto;
    }

    footer.main {
        margin: box-model(l, 0);
        text-align: center;

        a {
            margin: 0 box-model(xs);
        }
    }

    .tags, .model {
        margin-bottom: box-model(xl);
    }

    .name, .what, .where, .code, .enum {
        @include secondary-font(bold);
    }

    .description, .data-types, .parameters, .responses {
        margin-bottom: box-model(m);
    }

    .base-url {
        cursor: pointer;
    }

    .image-wrapper {
        margin-bottom: box-model(l);

        img {
            border-radius: $base-border-radius;
            border: 1px solid color(base-300);
            box-shadow: $base-box-shadow;
        }
    }

    .getting-started .image-wrapper {
        text-align: center;
        margin-top: box-model(l);
    }

    .user-tokens {
        h1 {
            display: none;
        }
    }

    .secondary-page-toolbar {
        margin: box-model(m, 0);
        text-align: right;
    }
}

nav.main {
    background-color: color(base-300);
    position: sticky;
    top: 0;
    margin-bottom: box-model(xl);
    text-align: right;
    z-index: 1;

    a.logo {
        float: left;
        padding-top: box-model(xxs);
    }

    img {
        display: block;
    }

    menu {
        display: inline-block;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li {
        display: inline-block;
        padding: box-model(xs, m);

        &:last-child {
            padding-right: 0;
        }

        a {
            display: block;
            color: color(base-700);
            padding: box-model(xs, 0);
            border-bottom: box-model(xxs) solid transparent;

            &.router-link-active {
                border-color: color(primary-300);
            }
        }
    }
}

.home, .limitations {
    max-width: $screen-sm-max;
    margin: 0 auto;
}

.schema {
    margin-bottom: box-model(m);

    section.properties {
        margin-top: box-model(m);
    }

    &:only-child, p.desc {
        margin-bottom: 0;
    }

    p.desc + .properties {
        margin-top: box-model(m);
    }

    .nav {
        margin-bottom: box-model(xs);
    }

    .example {
        @include secondary-font(bold);
        background-color: color(base-700);
        border-radius: $base-border-radius;
        box-sizing: border-box;
        color: color(base-000);
        padding: box-model(m);
        white-space: pre;
        margin: box-model(xs, 0);
    }
}

.api-path {
    .expandable .toggler {
        i {
            @include font-size(xxm);
        }
    }

    .heading {
        @include line-height(xxm);
        display: flex;
        padding: box-model(xxs);

        .verb {
            @include font-weight(semibold);
            text-transform: uppercase;
            border-radius: $base-border-radius;
            color: color(base-000);
            padding: 0 box-model(xs);
            margin-right: box-model(m);
            min-width: box-model(xs) * 10;
            text-align: center;
        }

        .path {
            @include secondary-font(bold);
        }
    }

    .path-info {
        border-style: solid;
        border-width: 1px;
        border-radius: $base-border-radius;
        margin-bottom: box-model(m);

        &.post {
            background-color: color(success-100);
            border-color: color(success-300);

            &.open > section {
                border-top: 1px solid color(success-300);
            }

            .verb { background-color: color(success-300); }
        }

        &.get {
            background-color: color(primary-100);
            border-color: color(primary-300);

            &.open > section {
                border-top: 1px solid color(primary-300);
            }

            .verb { background-color: color(primary-300); }
        }

        &.put {
            background-color: color(warning-100);
            border-color: color(warning-300);

            &.open > section {
                border-top: 1px solid color(warning-300);
            }

            .verb { background-color: color(warning-300); }
        }

        &.delete {
            background-color: color(error-100);
            border-color: color(error-300);

            &.open > section {
                border-top: 1px solid color(error-300);
            }

            .verb { background-color: color(error-300); }
        }
    }

    .details {
        padding: 0 box-model(m);
    }

    .key {
        flex: 1 1 20%;
    }

    .value {
        flex: 1 1 80%;
    }

    .what {
        @include font-size(s);
    }

    .where {
        color: color(base-500);
    }

    td.key {
        width: box-model(xs) * 20;
    }

    td.resp {
        width: box-model(xxl);
    }
}

.api-path .required, .model .required {
    @include font-size(s);
    color: color(error-300);
    vertical-align: super;
}


