.access-webapp-modal {
    .modal-body {
        padding: box-model(l);
        text-align: center;

        p {
            margin: box-model(l) 0;
        }

        pre {
            text-align: left;
        }
    }
}