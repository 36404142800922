.tab-content {
    > .tab-pane {
        display: none;
        padding-top: box-model(l);

        &.active {
            display: block;
        }
    }
}
