.help-modal {
    table {
        margin-bottom: box-model(m);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.date-format-info-modal {
    .unit, .token {
        width: 25%;
    }
}
