$notification-bg: (
    notification-error: color(error-300),
    notification-info: color(primary-300),
    notification-success: color(success-300),
    notification-warning: color(alert-300),

    notification-button-error: color(error-500),
    notification-button-info: color(primary-500),
    notification-button-success: color(success-500),
    notification-button-warning: color(alert-500)
);

.live-session-room.streamless {
    .vue-notification-group.qna-toaster {
        bottom: $notification-offset-y + box-model(l) !important;
        left: box-model(xxs) !important;
    }
}
.vue-notification-group {

    &.flashes {
        width: auto !important;
        bottom: $notification-offset-y !important;
        right: $notification-offset-x !important;
    }

    &.qna-toaster {
        bottom: $notification-offset-y !important;

        .vue-notification-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1px;
        }

        .vue-notification {
            background-color: color(base-700);
            margin: 0;
            padding: box-model(xxs, xs);
            width: fit-content;

            .message {
                @include font-size(s);
                margin: box-model(0, xs);
            }
        }
    }

    .vue-notification {
        @include font-size(base);

        background-color: transparent;
        border-radius: $base-border-radius;
        border: 0;
        box-shadow: $notifications-box-shadow;
        margin: box-model(m, 0, 0, xl);
        padding: box-model(m);
        width: $notification-width;

        color: color(base-000);
        .close {
            color: color(base-000);
        }
        button.confirm {
            color: color(base-000);
        }

        @each $type in error, info, success, warning {
            &.#{$type} {
                background-color: map-get($notification-bg, notification-#{$type});

                button.confirm {
                    background-color: map-get($notification-bg, notification-button-#{$type});
                }
            }
        }

        &.alert .ic-noty,
        &.warning .ic-noty {
            @include icon(ic-alert-triangle);
        }

        &.warn {
            @extend .warning;
        }

        &.error .ic-noty {
            @include icon(ic-minus-circle);
        }

        &.success .ic-noty {
            @include icon(ic-check-circle);
        }

        &.info .ic-noty {
            @include icon(ic-info);
        }

        .close {
            margin-right: 0;
        }

        .ic-noty {
            float: left;
            margin-right: box-model(xs);
            width: $notification-icon-size;
            text-align: center;
            margin-top: box-model(xxs);
        }

        .title {
            @include font-weight(semibold);
            margin-bottom: box-model(xs);
        }

        .message {
            @include line-height(s);

            margin: box-model(0, xl);
            overflow: hidden;
            overflow-wrap: break-word;
        }

        .actions {
            margin-top: box-model(m);
        }

        p {
            margin-bottom: 0;
        }

        button.confirm {
            margin-right: box-model(xs);
            margin-bottom: box-model(xs);
        }
    }
}

