.master-child-modal {
    display: flex;
    flex-direction: row;
    height: box-model(xs) * 75;
    max-height: $modal-base-max-height;
    border-radius: $large-border-radius;

    .master {
        flex: 0 0 35%;
        background-color: color(base-100);
        border-radius: inherit;

        .title {
            margin-top: box-model(m);
            margin-left: box-model(m);
            margin-right: box-model(m);
            padding: box-model(s);
        }

        .section {
            cursor: pointer;
            margin-left: box-model(m);
            margin-right: box-model(m);
            padding-top: box-model(xs);
            padding-bottom: box-model(xs);
            padding-left: box-model(s);
            padding-right: box-model(s);

            &:hover {
                background-color: color(base-300);
                border-radius: $base-border-radius;
            }

            &.selected {
                background-color: color(primary-300);
                color: color(base-000);
                border-radius: $base-border-radius;
            }
        }
    }

    .child {
        flex: 1 0 65%;
        display: none;
        min-width: 0;

        &.selected {
            display: flex;
            flex-direction: column;
        }

        .header {
            padding: box-model(l);

            h3 {
                border: none;
                margin: 0;
                padding: 0;
            }

            .close {
                margin-right: - box-model(s);
            }
        }

        .body {
            flex: 1 1 auto;
            padding-left: box-model(l);
            padding-right: box-model(l);
            padding-bottom: box-model(m);
            overflow: auto;
        }

        .footer {
            margin-left: box-model(l);
            margin-right: box-model(l);
            margin-bottom: box-model(l);
            padding-top: box-model(l);
            border-top: 1px solid color(base-100);
            text-align: right;
        }
    }
}
