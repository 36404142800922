.nav {
    $border-thickness: 2px;

    list-style-type: none;
    margin: 0;
    padding: 0;

    .nav-item, .nav-tab-item {
        @include font-weight(semibold);
        @include line-height(base);
        display: inline-block;
        margin-right: $nav-item-horizontal-margin-right;
        margin-bottom: $nav-item-horizontal-margin-bottom;
        border-bottom: $border-thickness solid transparent; // Prevents 'jumping' effect on hover

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            border-bottom-color: color(base-500);
        }

        &.active {
            border-bottom-color: color(primary-300);

            > a {
                color: color(primary-300);
            }
        }

        > a {
            display: block;
            text-decoration: none;
            color: color(base-500);
            padding-bottom: (box-model(xs)) - $border-thickness;
        }

        .badge {
            margin-left: box-model(xs);
        }
    }
}

ul.nav {
    margin-bottom: box-model(l);

    &.nav-list:last-child {
        margin-bottom: 0;
    }
}

.nav-list {
    margin-bottom: $nav-item-vertical-margin;

    > li {
        @include font-weight(normal);

        &:last-child {
            margin-bottom: 0;
        }

        &.nav-header {
            // todo: @extend h5 - phase 3
            @include font-size(base);
            @include font-weight(semibold);
            @include line-height(base);

            + .nav-sub-header {
                margin-top: 0;
            }
        }

        &.nav-sub-header {
            // todo: @extend h6 - phase 3
            @include font-size(s);
            @include font-weight(semibold);
            @include line-height(xxs);
            color: color(base-600);
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-top: box-model(m);
        }

        &.nav-header,
        &.nav-sub-header,
        > a {
            padding: box-model(xs, s);
        }

        > a {
            @include ellipsis;
            @include line-height(base);
            display: block;
            text-decoration: none;
            border-radius: $base-border-radius;

            &:hover {
                background-color: color(base-300);
            }

            > [class^='icon-'], > [class*=' icon-'] {
                @include line-height(base);
            }
        }

        &.active > a {
            background-color: color(primary-300);
            color: light-color(base-000);
        }
    }
}

.nav-list-wrapper {
    border-radius: $base-border-radius;
    background-color: color(base-100);
    padding: box-model(m);

    a {
        color: color(base-700);
    }
}
