
.bulk-modal {
    .kind-external-field {
        .form-generator-field-label {
            display: none;
        }
    }

    .form-generator-row {
        padding-left: 60px;
        position: relative;

        .bulk-edit-checkbox {
            position: absolute;
            top: 0;
            left: 8px;
        }
    }

    .modal-body {
        margin-top: 16px;
    }

    .time-shift-form {
        margin-top: 24px;

        tr {
            display: block;
            padding-bottom: 8px;
            td {
                padding: 0px;
            }
        }

        .radio-button {
            width: 20px;
        }

        .flex-cell {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 32px;
        }
    }
}