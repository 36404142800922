@charset 'UTF-8';

@use "sass:math";

// Normalization

@import "normalize.css/normalize.css";

// Main manifest file

@import 'abstracts/functions';
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/icons';

// Base styles

@import 'default/animations';
@import 'default/layout';
@import 'default/commons';

// Components

@import 'default/components/alerts';
@import 'default/components/badges';
@import 'default/components/buttons';
@import 'default/components/code';
@import 'default/components/expandable-content';
@import 'default/components/forms';
@import 'default/components/icons';
@import 'default/components/listings';
@import 'default/components/loader';
@import 'default/components/modals';
@import 'default/components/navs';
@import 'default/components/notifications';
@import 'default/components/tables';
@import 'default/components/tabs';
@import 'default/components/tooltips';
@import 'default/components/modals';

// Pages
@import 'default/pages/docs';
@import 'default/pages/user-profile';

// Third party
@import 'vendor/vue-multiselect';
