// Structural layout
// -----------------

html {
    &.backstage:not(:has(.maximized-view-mode-active)) {
        &, body {
            scrollbar-gutter: stable;
        }
    }
}

body {
    @include font-size(base);
    @include line-height(base);
    @include primary-font;

    background-color: color(base-000);
    color: color(base-700);

    #privileged-content-wrapper {
        transition: $main-content-menu-collapse-transition;
        margin-left: $main-menu-width;
        min-height: 100vh;
        overflow: auto;
    }

    &.working * {
        cursor: wait;
    }

    &.maximized, &.no-menu {
        #privileged-content-wrapper {
            margin-left: 0;
        }

        nav.main {
            display: none;
        }
    }

    &.no-menu {
        .content-wrapper.live-streams {
            max-width: none;
            padding: 0;
        }
    }
}

html.minified-menu #privileged-content-wrapper {
    margin-left: $main-menu-minimized-width;
}

#main-content {
    padding: 0;
}

%content-wrapper {
    min-width: $main-container-min-width;
    max-width: $main-container-max-width;
    padding-left: box-model(xl);
    padding-right: box-model(xl);
    margin: 0 auto;
}

%content-wrapper-full {
    min-width: auto;
    max-width: none;
    padding-bottom: 0;
}

.content-wrapper {
    @extend %content-wrapper;

    padding-top: box-model(m) + box-model(l);
    padding-bottom: box-model(xl) + $indirect-button-heights; // consider bottom bar top and bottom paddings + buttons height;

    &.live-streams {
        padding-bottom: 0;
    }
}

.inner-page {
    padding-bottom: box-model(xl);
}

.subpage {
    .content {
        display: flex;
        align-items: flex-start;

        .nav-list-wrapper {
            width: $submenu-width;
            box-sizing: border-box;
            flex-shrink: 0;
        }

        .view-wrapper {
            flex-grow: 1;
            padding-left: box-model(xl);
            min-width: 0;
        }
    }
}

.page-actions {
    float: right;
}

.panels {
    container-name: panels;
    container-type: inline-size;

    display: flex;
    flex-wrap: wrap;

    $panel-side-paddings: box-model(m);

    // No, paddings here is not an error ;)
    margin-left: $panel-side-paddings * -1;
    margin-right: $panel-side-paddings * -1;

    &.narrow {
        margin: 0;

        > .panel {
            padding: 0;
        }
    }

    .panel {
        padding: 0 $panel-side-paddings;
        margin-bottom: box-model(m);
        box-sizing: border-box;

        &:nth-last-child(1),
        &:nth-last-child(2) {
            margin-bottom: 0;
        }

        &:only-child {
            max-width: none !important;
        }
    }

    /**
     * Evenly distributed columns.
     * NOTE: first column grows.
     *
     *    +-------------+
     *    |+-----++----+|
     *    ||     ||    ||
     *    |+-----++----+|
     *    |+-----++----+|
     *    ||     ||    ||
     *    |+-----++----+|
     *    |+-----------+|
     *    ||           ||
     *    |+-----------+|
     *    +-------------+
     */
    &.panels-a {
        > .panel {
            flex: 1 1 50%;
        }
    }

    /**
     * First column significantly larger than second column.
     * NOTE: first column grows.
     *
     *    +-------------+
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-----------+|
     *    ||           ||
     *    |+-----------+|
     *    +-------------+
     */
    &.panels-b {
        > .panel {
            $odd-col-size: 66.66%;

            &:nth-child(odd) {
                flex: 1 1 $odd-col-size;
                max-width: $odd-col-size;
            }

            &:nth-child(even) {
                flex: 1 1 #{100% - $odd-col-size};
                max-width: #{100% - $odd-col-size};
            }
        }
    }

    /**
     * First column significantly larger than second column.
     * NOTE: first column grows.
     *
     *    +-------------+
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-----------+|
     *    ||           ||
     *    |+-----------+|
     *    +-------------+
     */
    &.panels-b1 {
        > .panel {
            $odd-col-size: 60%;

            &:nth-child(odd) {
                flex: 1 1 $odd-col-size;
                max-width: $odd-col-size;
            }

            &:nth-child(even) {
                flex: 1 1 #{100% - $odd-col-size};
                max-width: #{100% - $odd-col-size};
            }
        }
    }

    /**
     * First column significantly larger than second column.
     * NOTE: first column does NOT grow.
     *
     *    +-------------+
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-------++--+|
     *    ||       ||  ||
     *    |+-------++--+|
     *    |+-------+    |
     *    ||       |    |
     *    |+-------+    |
     *    +-------------+
     */
    &.panels-b2 {
        > .panel {
            $odd-col-size: 60%;

            &:nth-child(odd) {
                flex: 0 1 $odd-col-size;
                max-width: $odd-col-size;
            }

            &:nth-child(even) {
                flex: 1 1 #{100% - $odd-col-size};
                max-width: #{100% - $odd-col-size};
            }
        }
    }


    /**
     * First column slighly smaller than second column
     * NOTE: first column DOESN'T grow.
     *
     *    +-------------+
     *    |+----++-----+|
     *    ||    ||     ||
     *    |+----++-----+|
     *    |+----++-----+|
     *    ||    ||     ||
     *    |+----++-----+|
     *    |+----+       |
     *    ||    |       |
     *    |+----+       |
     *    +-------------+
     */
    &.panels-c {
        > .panel {
            $odd-col-size: 40%;

            &:nth-child(odd) {
                flex: 0 1 $odd-col-size;
            }

            &:nth-child(even) {
                flex: 1 1 #{100% - $odd-col-size};
            }
        }
    }
}

@container panels (width < #{$screen-md-min}) {
    .panels.panels > .panel {
        &:nth-child(odd) {
            margin-bottom: box-model(l);
        }

        &:nth-child(odd),
        &:nth-child(even) {
            flex: 1 0 100%;
            max-width: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
